<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="650">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Update Find My Car</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.QRCodeID"
                        label="ID"
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Operator"
                    v-model="form.operator.selected"
                    @change="execFilter('operator')"
                    :items="form.operator.items"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="form.operator.rule"
                    :loading="form.operator.loader"
                    :disabled="readonly"
                    hide-details="auto"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park *"
                    v-model="form.carpark.selected"
                    @change="execFilter('carpark')"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    :disabled="readonly"
                    hide-details="auto"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CarParkType"
                        label="Type"
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.zone.item"
                        :rules="form.zone.rule"
                        label="Zone *"
                        required
                        hide-details="auto"
                        :disabled="readonly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.level.item"
                        :rules="form.level.rule"
                        label="Level / Street *"
                        required
                        hide-details="auto"
                        :disabled="readonly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.pillarbay.item"
                        :rules="form.pillarbay.rule"
                        label="Pillar / Bay No. *"
                        required
                        hide-details="auto"
                        :disabled="readonly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.QRCodeURL"
                        label="URL"
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" oncontextmenu="return false" onselectstart="return false" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false">
                    <qrcode-vue class="qr-canvas" :value="form.qrcodeurl.item" size="200"></qrcode-vue>
                    <div style="display: none"><qrcode-vue class="qr-canvas" :value="form.qrcodeurl.item" size="800"></qrcode-vue></div>
                </v-col>
                <v-col cols="12" md="12">
                    <v-btn small tile color="success" @click="downloadPNG">Download QR</v-btn>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.CreatedDateTime"
                        label="Created Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="modalInfo.UpdatedDateTime"
                        label="Updated Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn v-if="validateAccess('fmc_delete')" tile color="error darken-1" small @click="handleDelete('delete')" class="ml-2">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import alertbox from './Alert';
import {rule} from '../../assets/js/checkPerm';
import QrcodeVue from 'qrcode.vue'
import { saveAs } from 'file-saver'

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'carpark', 'operator'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'User ID', value: 'userID' },
                { text: 'Type', value: 'Type' },
                { text: 'Role', value: 'Role'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                zone: {item: "", rule: []},
                level: {item: "", rule: []},
                pillarbay: {item: "", rule: []},
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                operator: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                qrcodeurl: {item: ''}
            },
        }
    },

    components: {alertbox, QrcodeVue},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async beforeCreateForm(flag){
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            this.form.carpark.rule = [
                v => !!v || 'This is required',
            ];

            this.form.zone.rule = [
                v => !!v || 'This is required',
            ];

            this.form.level.rule = [
                v => !!v || 'This is required',
            ];

            this.form.pillarbay.rule = [
                v => !!v || 'This is required',
            ];

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.form.zone.rule = [];
            this.form.zone.item = this.modalInfo.Zone;

            this.form.level.rule = [];
            this.form.level.item = this.modalInfo.Level;

            this.form.pillarbay.rule = [];
            this.form.pillarbay.item = this.modalInfo.PillarOrBay;

            this.form.operator.selected = this.modalInfo.OperatorID;
            let operator = [];
            operator.push({name: 'Select', OperatorID: ''});
            for (const [index, item] of this.operator.entries()){
                operator.push(item);
            }
            this.form.operator.items = operator;

            this.form.carpark.rule = [];
            this.form.carpark.selected = this.modalInfo.CarParkID;
            let carpark = [];

            carpark.push({name: 'Select', CarParkID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;
            this.form.qrcodeurl.item = this.modalInfo.QRCodeURL;
        },

        downloadPNG(){
            this.$nextTick(() => {
                let can = document.getElementsByTagName('canvas');
                let img = new Image();
                img.src = can[1].toDataURL();
                console.log('img', img.src);
                var file = this.dataURLtoFile(img.src, `${this.modalInfo.CarParkID}_${this.modalInfo.Zone}_${this.modalInfo.Level}_${this.modalInfo.PillarOrBay}.png`);
                saveAs(file);
            });
        },

        execFilter(item){
            if (item == 'operator'){
                this.form.carpark.items = [];
                this.form.carpark.selected = '';

                if (this.form.operator.selected){
                    let itm = this.carpark.filter(i => i.OperatorID == this.form.operator.selected);

                    this.form.carpark.items.push({name: 'Select', CarParkID: ''});

                    for(let i=0; i<itm.length; i++){
                        this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }

                else{
                    this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                    let itm = this.carpark;
                    for(let i=0; i<itm.length; i++){
                        this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }
            }
        },

        handleDelete(value){
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'update'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }

                if (this.ma.modal_action_type == 'delete'){
                    this.form.flag = 'delete';
                    this.$emit('delete');
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        }
    }
  }
</script>