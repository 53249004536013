<template>
    <v-row justify="center">
    <v-dialog v-model="dialogLocal" width="650">
      <v-card>
        <v-form ref="es" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
          <span class="headline">Download QR Code</span>
          <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-overlay
                        :absolute="true"
                        :opacity="1"
                        :z-index="999"
                        :value="modal_loader"
                        color="#D2D7DF"
                        >
                        <v-progress-circular indeterminate size="100"></v-progress-circular>
                        </v-overlay>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Operator"
                    v-model="form.operator.selected"
                    @change="execFilter('operator')"
                    :items="form.operator.items"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="form.operator.rule"
                    :loading="form.operator.loader"
                    :disabled="form.operator.disable"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select"
                    label="Car Park *"
                    v-model="form.carpark.selected"
                    @change="execFilter('carpark')"
                    :items="form.carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form.carpark.rule"
                    :loading="form.carpark.loader"
                    :disabled="form.carpark.disable"
                    >
                    </v-autocomplete>
                    <div v-if="qrcodes.length > 0">
                        <div v-for="(item, index) of qrcodes" :key="index" style="padding: 10px; display: none">
                        <qrcode-vue class="qr-canvas" :value="item" size="600"></qrcode-vue>
                        </div>
                    </div>
                    <span v-if="message" class="text-body-2" style="color: red">No QR Codes available for this Car Park</span>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Download</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    </v-row>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

  export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'readonly', 'carpark', 'operator'],

    data() {
        return {
            dialogLocal: false,
            items: [],
            headers: [
                { text: '#', value: 'row' },
                { text: 'ID', value: 'ID' },
                { text: 'User ID', value: 'userID' },
                { text: 'Type', value: 'Type' },
                { text: 'Role', value: 'Role'},
            ],
            tableloading: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            innerModal: false,
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            form: {
                zone: {item: "", rule: []},
                level: {item: "", rule: []},
                pillarbay: {item: "", rule: []},
                carpark: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                operator: {
                    items: [],
                    itemsFull: [],
                    selected: '',
                    loader: false,
                    disable: false,
                    rule: []
                },
                qrcodeurl: {item: ''}
            },
            qrcodes: [],
            modal_loader: false,
            message: false
        }
    },

    components: {QrcodeVue},

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async getQRCodes(){
            this.modal_loader = true;
            this.message = false;
            this.qrcodes = [];

            try{
                let response = await this.$store.dispatch("getFindMyCarAll", {page: 1, operatorid: this.form.operator.selected, carparkid: this.form.carpark.selected, search: this.search, rowresultsperpage: null });

                if (response.data.code === 'AP000'){
                    let dt = response.data.record;
                    for (let i=0; i<dt.length; i++){
                        this.qrcodes.push(dt[i].QRCodeURL);
                    }

                    this.modal_loader = false;

                    if (dt.length > 0){
                        this.$nextTick(() => {
                            let can = document.getElementsByTagName('canvas');
                            let arrOfFiles = [];
                            let zip = new JSZip();
                            let self = this;
                            can.forEach((doc, i) => {
                                let img = new Image();
                                img.src = can[i].toDataURL();
                                let filename = dt[i].QRCodeURL.substring(dt[i].QRCodeURL.lastIndexOf('/') + 1);
                                var file = this.dataURLtoFile(img.src, `${dt[i].CarParkID}_${dt[i].Zone}_${dt[i].Level}_${dt[i].PillarOrBay}.png`);
                                arrOfFiles.push(file);
                                zip.file(`${filename}_${dt[i].CarParkID}_${dt[i].Zone}_${dt[i].Level}_${dt[i].PillarOrBay}.png`, file);
                            });

                            zip.generateAsync({type:"blob"})
                            .then(function(content) {
                                let carparkname = self.form.carpark.items.find(itm => self.form.carpark.selected === itm.CarParkID).name;
                                saveAs(content, `QRCODES_${carparkname}.zip`);
                            });
                        });
                    }

                    else{
                        this.message = true;
                    }
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async beforeCreateForm(flag){
            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            this.form.carpark.rule = [
                v => !!v || 'This is required',
            ];

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.es.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                    self.getQRCodes();
                }
            });
        },

        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.message = false;
            this.form.operator.selected = "";
            this.qrcodes = [];
            let operator = [];
            operator.push({name: 'Select', OperatorID: ''});
            for (const [index, item] of this.operator.entries()){
                operator.push(item);
            }
            this.form.operator.items = operator;

            this.form.carpark.rule = [];
            this.form.carpark.selected = "";
            let carpark = [];

            carpark.push({name: 'Select', CarParkID: ''});
            for (const [index, item] of this.carpark.entries()){
                carpark.push(item);
            }
            this.form.carpark.items = carpark;
        },

        execFilter(item){
            if (item == 'operator'){
                this.form.carpark.items = [];
                this.form.carpark.selected = '';

                if (this.form.operator.selected){
                    let itm = this.carpark.filter(i => i.OperatorID == this.form.operator.selected);

                    this.form.carpark.items.push({name: 'Select', CarParkID: ''});

                    for(let i=0; i<itm.length; i++){
                        this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }

                else{
                    this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                    let itm = this.carpark;
                    for(let i=0; i<itm.length; i++){
                        this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }
            }
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        }
    }
  }
</script>